<template>
  <div class="indexcontainer">
    <div class="iheader">
      <div class="lefttitle">
        <div class="ih-title" @click="topage('index')">土地数据</div>
        <div class="ih-title titleselect" @click="topage('dataindex')" >经济数据</div>
        <div class="ih-content">
        
          <div style="margin-left:20px;" class="indexblock">
            <el-date-picker :picker-options="pickerOptions" v-model="year" type="year" value-format="yyyy" size="mini"
              placeholder="选择年">

            </el-date-picker>
          </div>

        </div>
        <el-button size="mini" type="primary" @click="getData" style="margin-left:20px;" icon="el-icon-search">
          查询
        </el-button>
      </div>
    </div>
    <div class="icontent">
      <div class="ileft">
        <div class="ilt">
          <div class="iltl">
            <div class="dtzc itembox">
              <div class="item" @click="toUrl(iconname.gdtz.route)">

                <img class="iicon" :src="iconname.gdtz.icon">
                <div class="ivalue">{{ listdata.gdtz.value }}</div>
                <div class="iname">{{ iconname.gdtz.name }}</div>
                <div class="itongbi">
                  <span :class="{ 'downrise': listdata.gdtz.rise === false }">
                    {{ listdata.gdtz.tongbi }}
                  </span>
                  <span v-if="listdata.gdtz.tongbi !== '-'">
                    <img v-if="listdata.gdtz.rise" src="/pic/rise.png" />
                    <img v-else src="/pic/down.png" /></span>

                </div>

              </div>
            </div>
            <div class="jrjg itembox">
              <div class="item" @click="toUrl(iconname.jrjg.route)">

                <img class="iicon" :src="iconname.jrjg.icon">
                <div class="ivalue">{{ listdata.jrjg.value }}</div>
                <div class="iname">{{ iconname.jrjg.name }}</div>
                <div class="itongbi">


                  <span :class="{ 'downrise': listdata.jrjg.rise === false }">
                    {{ listdata.jrjg.tongbi }}
                  </span>
                  <span v-if="listdata.jrjg.tongbi !== '-'">
                    <img v-if="listdata.jrjg.rise" src="/pic/rise.png" />
                    <img v-else src="/pic/down.png" /></span>
                </div>

              </div>
            </div>
          </div>
          <div class="iltr">

            <div class="sjly itembox">
              <div class="item" @click="toUrl(iconname.sjly.route)">

                <img class="iicon" :src="iconname.sjly.icon">
                <div class="ivalue">{{ listdata.sjly.value }}</div>
                <div class="iname">{{ iconname.sjly.name }}</div>
                <div class="itongbi">
                  <span :class="{ 'downrise': listdata.sjly.rise === false }">
                    {{ listdata.sjly.tongbi }}
                  </span>
                  <span v-if="listdata.sjly.tongbi !== '-'">
                    <img v-if="listdata.sjly.rise" src="/pic/rise.png" />
                    <img v-else src="/pic/down.png" /></span>
                </div>

              </div>
            </div>
            <div class="qshyd itembox">
              <div class="item" @click="toUrl(iconname.qshyd.route)">

                <img class="iicon" :src="iconname.qshyd.icon">
                <div class="ivalue">{{ listdata.qshyd.value }}</div>
                <div class="iname">{{ iconname.qshyd.name }}</div>
                <div class="itongbi">

                  <span :class="{ 'downrise': listdata.qshyd.rise === false }">
                    {{ listdata.qshyd.tongbi }}
                  </span>
                  <span v-if="listdata.qshyd.tongbi !== '-'">
                    <img v-if="listdata.qshyd.rise" src="/pic/rise.png" />
                    <img v-else src="/pic/down.png" /></span>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="ilb">
          <div class="shxf itembox">
            <div class="item" @click="toUrl(iconname.shxf.route)">

              <img class="iicon" :src="iconname.shxf.icon">
              <div class="ivalue">{{ listdata.shxf.value }}</div>
              <div class="iname">{{ iconname.shxf.name }}</div>
              <div class="itongbi">


                <span :class="{ 'downrise': listdata.shxf.rise === false }">
                  {{ listdata.shxf.tongbi }}
                </span>
                <span v-if="listdata.shxf.tongbi !== '-'">
                  <img v-if="listdata.shxf.rise" src="/pic/rise.png" />
                  <img v-else src="/pic/down.png" /></span>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="icenter">
        <div class="gmys itembox">
          <div class="item" @click="toUrl(iconname.gmys.route)">

            <img class="iicon" :src="iconname.gmys.icon">
            <div class="ivalue">{{ listdata.gmys.value }}</div>
            <div class="iname">{{ iconname.gmys.name }}</div>
            <div class="itongbi">
              <span :class="{ 'downrise': listdata.gmys.rise === false }">
                {{ listdata.gmys.tongbi }}
              </span>
              <span v-if="listdata.gmys.tongbi !== '-'">
                <img v-if="listdata.gmys.rise" src="/pic/rise.png" />
                <img v-else src="/pic/down.png" /></span>
            </div>

          </div>
        </div>
        <div class="gdp itembox">
          <div class="item" @click="toUrl(iconname.gdp.route)">

            <img class="iicon" :src="iconname.gdp.icon">
            <div class="ivalue" style="font-weight:bold">{{ listdata.gdp.value }}</div>
            <div class="iname" style="font-weight:bold;font-size:14px;">{{ iconname.gdp.name }}</div>


          </div>
        </div>



        <div class="nckzbsr itembox hight25">
          <div class="item" @click="toUrl(iconname.jck.route)">

            <img class="iicon" :src="iconname.jck.icon">
            <div class="r">
              <div class="rt">
                <div class="ivalue">{{ listdata.jck.value }}</div>
                <div class="itongbi">

                  <span :class="{ 'downrise': listdata.jck.rise === false }">
                    {{ listdata.jck.tongbi }}
                  </span>
                  <span v-if="listdata.jck.tongbi !== '-'">
                    <img v-if="listdata.jck.rise" src="/pic/rise.png" />
                    <img v-else src="/pic/down.png" /></span>
                </div>
              </div>
              <div class="iname">{{ iconname.jck.name }}</div>

            </div>

          </div>
        </div>





        <!-- <div class="ybgg itembox">
          <div class="item" @click="toUrl(listdata.jck.route)">

            <img class="iicon" :src="iconname.jck.icon">
            <div class="ivalue">{{ listdata.jck.value }}</div>
            <div class="iname">{{ iconname.jck.name }}</div>
            <div class="itongbi">
              <span>
                {{ listdata.jck.tongbi }}
              </span>
              <span v-if="listdata.gdtz.tongbi !== '-'">
                    <img v-if="listdata.gdtz.rise" src="/pic/rise.png" />
              <img v-else src="/pic/down.png" /></span>
            </div>

          </div>
        </div> -->
      </div>
      <div class="iright">
        <div class="irt">
          <div class="nckzbsr itembox">
            <div class="item" @click="toUrl(iconname.nckzbsr.route)">

              <img class="iicon" :src="iconname.nckzbsr.icon">
              <div class="r">
                <div class="rt">
                  <div class="ivalue">{{ listdata.nckzbsr.value }}</div>
                  <div class="itongbi">
                    <span :class="{ 'downrise': listdata.nckzbsr.rise === false }">
                      {{ listdata.nckzbsr.tongbi }}
                    </span>
                    <span v-if="listdata.nckzbsr.tongbi !== '-'">
                      <img v-if="listdata.nckzbsr.rise" src="/pic/rise.png" />
                      <img v-else src="/pic/down.png" /></span>
                  </div>
                </div>
                <div class="iname">{{ iconname.nckzbsr.name }}</div>

              </div>

            </div>
          </div>
          <div class="czkzbsr itembox">
            <div class="item" @click="toUrl(iconname.czkzbsr.route)">

              <img class="iicon" :src="iconname.czkzbsr.icon">
              <div class="r">
                <div class="rt">
                  <div class="ivalue">{{ listdata.czkzbsr.value }}</div>
                  <div class="itongbi">
                    <span :class="{ 'downrise': listdata.czkzbsr.rise === false }">
                      {{ listdata.czkzbsr.tongbi }}
                    </span>
                    <span v-if="listdata.czkzbsr.tongbi !== '-'">
                      <img v-if="listdata.czkzbsr.rise" src="/pic/rise.png" />
                      <img v-else src="/pic/down.png" /></span>
                  </div>
                </div>
                <div class="iname">{{ iconname.czkzbsr.name }}</div>

              </div>

            </div>
          </div>
        </div>
        <div class="irb">
          <div class="irbr">


            <div class="ssgs itembox">
              <div class="item" @click="toUrl(iconname.ggymsr.route)">

                <img class="iicon" :src="iconname.ggymsr.icon">
                <div class="ivalue">{{ listdata.ggymsr.value }}</div>
                <div class="iname">{{ iconname.ggymsr.name }}</div>
                <div class="itongbi">
                  <span :class="{ 'downrise': listdata.ggymsr.rise === false }">
                    {{ listdata.ggymsr.tongbi }}
                  </span>
                  <span v-if="listdata.ggymsr.tongbi !== '-'">
                    <img v-if="listdata.ggymsr.rise" src="/pic/rise.png" />
                    <img v-else src="/pic/down.png" /></span>
                </div>

              </div>
            </div>
            <div class="czrk itembox">
              <div class="item" @click="toUrl(iconname.ggymzc.route)">

                <img class="iicon" :src="iconname.ggymzc.icon">
                <div class="ivalue">{{ listdata.ggymzc.value }}</div>
                <div class="iname">{{ iconname.ggymzc.name }}</div>
                <div class="itongbi">
                  <span :class="{ 'downrise': listdata.ggymzc.rise === false }">
                    {{ listdata.ggymzc.tongbi }}
                  </span>
                  <span v-if="listdata.ggymzc.tongbi !== '-'">
                    <img v-if="listdata.ggymzc.rise" src="/pic/rise.png" />
                    <img v-else src="/pic/down.png" /></span>
                </div>

              </div>
            </div>

          </div>
          <div class="irbr">
            <div class="ssgs itembox">
              <div class="item" @click="toUrl(iconname.ssgs.route)">

                <img class="iicon" :src="iconname.ssgs.icon">
                <div class="ivalue">{{ listdata.ssgs.value }}</div>
                <div class="iname">{{ iconname.ssgs.name }}</div>
                <div class="itongbi">
                  <span :class="{ 'downrise': listdata.ssgs.rise === false }">
                    {{ listdata.ssgs.tongbi }}
                  </span>
                  <span v-if="listdata.ssgs.tongbi !== '-'">
                    <img v-if="listdata.ssgs.rise" src="/pic/rise.png" />
                    <img v-else src="/pic/down.png" /></span>
                </div>

              </div>
            </div>
            <div class="czrk itembox">
              <div class="item" @click="toUrl(iconname.czrk.route)">

                <img class="iicon" :src="iconname.czrk.icon">
                <div class="ivalue">{{ listdata.czrk.value }}</div>
                <div class="iname">{{ iconname.czrk.name }}</div>
                <div class="itongbi">
                  <span :class="{ 'downrise': listdata.czrk.rise === false }">
                    {{ listdata.czrk.tongbi }}
                  </span>
                  <span v-if="listdata.czrk.tongbi !== '-'">
                    <img v-if="listdata.czrk.rise" src="/pic/rise.png" />
                    <img v-else src="/pic/down.png" /></span>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { indexlist } from "@/api/index.js";

export default {
  data() {
    let cyear = new Date().getFullYear(); /* 获取现在的年份 */
    cyear = new Date(new Date().setFullYear(cyear - 1)).getFullYear()


    // var cyear = new Date().getFullYear();
    var cmonth = new Date().getMonth();
    var currentMonth = "";
    if (cmonth !== 12) {
      var cm = "";
      if (cmonth + 1 < 10) {
        cm = "0" + (cmonth + 1);
      } else {
        cm = (cmonth + 1).toString();
      }
      currentMonth = cyear + "-" + cm;
    } else {
      currentMonth = cyear + 1 + "01";
    }






    return {
      listdata: {
        gdtz: {
          value: "",
        },
      },
      //禁用当前日期之前的日期
      pickerOptions: {
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() > Date.now() - 8.64e7;
        },
      },
      radio: "1",
      year: cyear.toString(),
      month: currentMonth,
      iconname: {
        gdtz: {
          icon: "/pic/gdzc.png",
          name: "固定资产投资(亿元)",
          route: '/econ/gutou'
        },
        jrjg: {
          icon: "/pic/jrjg.png",
          name: "金融机构存款(亿元)",
          route: '/econ/cun'
        },
        sjly: {
          icon: "/pic/sjly.png",
          name: "实际利用外资(亿美元)",
          route: '/econ/waizi'
        },
        qshyd: {
          icon: "/pic/qshyd.png",
          name: "全社会用电量(亿千瓦时)",
          route: '/econ/dian'
        },
        shxf: {
          icon: "/pic/shxf.png",
          name: "社会消费零售总额(亿元)",
          route: '/econ/shexiao'
        },
        gmys: {
          icon: "/pic/gmys.png",
          name: "规模以上工业总产值(亿元)",
          route: '/econ/gonggdp'
        },
        gdp: {
          icon: "/pic/gdp.png",
          name: "地区生产总值(亿元)",
          route: '/econ/gdp'
        },
        ybgg: {
          icon: "/pic/ybgg.png",
          name: "进出口总额(亿美元)",
          route: '/econ/jinchukou'
        },
        nckzbsr: {
          icon: "/pic/kzpsr.png",
          name: "农村常住居民人均可支配收入(元)",
          route: '/econ/shouzhi'
        },
        czkzbsr: {
          icon: "/pic/kzpsr.png",
          name: "城镇常住居民人均可支配收入(元)",
          route: '/econ/shouzhi'
        },
        jck: {
          icon: "/pic/jck.png",
          name: "进出口总额(亿美元)",
          route: '/econ/jinchukou'
        },
        ggymsr: {
          icon: "/pic/ybgg.png",
          name: "公共预算收入(亿元)",
          route: '/econ/caishou'
        },
        ggymzc: {
          icon: "/pic/ybgg.png",
          name: "公共预算支出(亿元)",
          route: '/econ/caizhi'
        },

    
        ssgs: {
          icon: "/pic/ssgs.png",
          name: "上市公司(家)",
          route: '/econ/qi'
        },
        czrk: {
          icon: "/pic/czrk.png",
          name: "常住人口(万人)",
          route: '/citypopulation'
        },
      },
    };
  },
  computed: {
    shi: {
      get() {
        return this.$store.state.shi
      },
      set(v) {
        this.$store.dispatch('changeShi', v);
      }
    }
  },
  watch: {
    shi: {
      handler(v) {

        this.getData()
      }
    }
  },

  mounted() {
    this.getIndexlist();
  },

  methods: {

    topage(item){
      this.$router.push(item)
    },

    getData() {
      this.getIndexlist()
    },

    getIndexlist() {
      const params = {
        city_plate_id: this.shi,
        year: this.year,
      };
      var that = this;
      if (this.shi &&








        this.shi != null) {
        indexlist(params).then((res) => {
   
          that.listdata = res.data;
        });
      }

    },
    toUrl(url) {
      this.$router.push(url)
    },
    toCalendar() {
      this.$router.push('/calendar')
    }
  },
};
</script>

<style lang="scss" scoped>
.irb {
  height: 60%;
  display: flex;

  .irbl {
    width: 50%;
    height: 100%;

    .jck {
      height: 100%;
    }
  }

  .irbr {
    width: 50%;
    height: 100%;

    .ssgs,
    .czrk {
      height: 50%;
    }


  }
}

.czkzbsr,
.nckzbsr {
  width: 100%;
  height: 50%;

  .iicon {
    margin: 0 30px;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .rt {
      display: flex;
    }
  }
}

.irt {
  height: 40%;
}

.gmys {
  height: 30%;
}

.gdp {
  height: 45%;

  .item {
    background: linear-gradient(180deg, #28a7e1 0%, #5bccff 100%);

    .ivalue,
    .iname {
      color: #fff;
    }

  }
}

.ybgg {
  height: 25%;
  display: flex;
  flex-direction: row;
}

.ilt {
  display: flex;
  height: 70%;
}

.ilb {
  width: 100%;
  height: 30%;
}

.iltl {
  width: 50%;
  height: 100%;
}

.itembox {
  padding: 10px;
  box-sizing: border-box;
}

.iltr {
  width: 50%;
  flex: 1;
}

.dtzc,
.qshyd {
  height: 40%;
}

.jrjg,
.sjly {
  height: 60%;
}

.shxf {
  height: 100%;
}

.item {
  cursor: pointer;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 50px;
    height: 50px;
  }

  .ivalue {
    font-size: 26px;
    font-family: STYuanti-TC-Bold, STYuanti-TC;
    font-weight: 400;
    color: #333640;
  }

  .iname {
    font-size: calc(100vw * 16 / 1920);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333640;
  }

  .itongbi {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fa5756;
    }

    .downrise {
      color: green
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.indexcontainer {
  background: #f0f3f8;
  width: 100%;
  min-width: 1200px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}

.iheader {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn {
    margin-right: 30px;
  }

  .lefttitle {
    display: flex;
    justify-content: center;
    align-items: center;

    .ih-title {
      margin-left: 30px;
      justify-content: center;
      align-items: center;
      height: 30px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #676b78;
      line-height: 30px;
      cursor: pointer;
    }

    .titleselect{
      font-size: 24px;
      color: #333640;
    }

  }

  .search {
    margin-left: 20px;
  }

  .ih-content {
    display: flex;

    justify-content: center;
    align-items: center;

    .action {
      display: flex;
      margin-left: 20px;
    }

    .indexblock {
      ::v-deep .el-input {
        width: 120px;
      }
    }
  }
}

.icontent {
  box-sizing: border-box;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 96%;
  margin-left: 2%;
  flex: 1;
  height: calc(100vh - 160px);

  .ileft {
    box-sizing: border-box;
    width: 35%;
    height: 94%;
    padding: 0 15px;
  }

  .icenter {
    box-sizing: border-box;
    width: 30%;
    padding: 0 15px;
    height: 94%;
  }

  .iright {
    box-sizing: border-box;
    width: 35%;
    padding: 0 15px;
    height: 94%;
  }
}

.hight25 {
  height: 25%;
}

.downrise {
  color: green;
}
</style>